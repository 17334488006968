/* src/App.css */
:root {
  --primary-color: #7f2d5e;
  --primary-dark: #662048;
  --secondary-color: #e3d2ca;
  --secondary-dark: #d4c0b8;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
}

header {
  background-image: url('./assets/images/bg.jpg'); /* Replace with the actual image URL */
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}

header h1 {
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

header h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

header a {
  display: inline-block;
  max-width: 250px;
  width: 100%;
  padding: 12px 25px;
  text-decoration: none;
  background-color: #7f2d5e;
  color: white;
  border-radius: 25px;
  margin: 5px auto;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

header a:hover {
  background-color: #662048;
}

section {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.about, .team, .highlights, .venue {
  text-align: center;
  margin-bottom: 40px;
}

.team .members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.team .member {
  flex: 1 1 calc(33% - 40px);
  max-width: 300px;
  border: none;
  border-radius: 15px;
  padding: 25px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(127, 45, 94, 0.1);
  transition: all 0.3s ease;
}

.team .member:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(127, 45, 94, 0.15);
}

.team .member h3 {
  color: #7f2d5e;
  margin-bottom: 8px;
}

.team .member p {
  color: #666;
  font-size: 0.95rem;
}

.footer {
  padding: 2rem 1rem;
  background-color: var(--secondary-color);
}

.footer-content {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

.footer-section {
  flex: 1;
}

.footer-section h4 {
  margin-bottom: 1rem;
}

.footer-section p {
  margin: 0.25rem 0;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #ddd;
}

footer a {
  color: #7f2d5e;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #662048;
  text-decoration: underline;
}

.navbar {
  background:  #7f2d5e;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(127, 45, 94, 0.2);
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar li {
  padding: 14px 20px;
}

.navbar a {
  color: white;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1.2rem;
  padding: 10px 15px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 5px;
}

.navbar a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}
.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 160px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.registration-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  padding-top: 160px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registration-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.registration-form label {
  font-weight: 600;
  color: #333;
}

.registration-form input,
.registration-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.registration-form textarea {
  min-height: 100px;
  resize: vertical;
}

.submit-button {
  background-color: #7f2d5e;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #662048;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .form-row {
    grid-template-columns: 1fr;
  }
}

.about {
  padding: 4rem 2rem;
  /* background-color: #f8f9fa; */
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
}

.about-title {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 3rem;
  font-size: 2.5rem;
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  text-align: justify;
}

.card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.signature {
  margin-top: 2rem;
  text-align: right;
}

.team {
  font-weight: bold;
  margin: 0.5rem auto;
}

.conference {
  color: #666;
  font-style: italic;
}

.package-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.package-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.package-button:hover {
  border-color: #7f2d5e;
  transform: translateY(-2px);
}

.package-button.selected {
  border-color: var(--primary-color);
  background-color: var(--secondary-color);
}

.workshop-only-section {
  margin-top: 2rem;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.workshop-only-text {
  margin-bottom: 1rem;
  color: #333;
}

.workshop-only-button {
  background-color: #7f2d5e;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}




.package-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.package-price {
  color: #666;
}

@media (max-width: 768px) {
  .about {
    padding: 2rem 1rem;
  }
  
  .about-title {
    font-size: 2rem;
  }
}

.confirmation-screen {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.summary-details {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.summary-row:last-child {
  border-bottom: none;
}

.total-amount {
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 20px;
  border-top: 2px solid #ddd;
  padding-top: 20px;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.back-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.2s;
  background-color: #e2e8f0;
  color: #4a5568;
  border: none;
  cursor: pointer;
}

.back-button:hover {
  background-color: #cbd5e0;
}

.payment-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.2s;
  background-color: #7f2d5e;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.payment-button:hover {
  background-color: #662048;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.payment-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.payment-button svg {
  width: 20px;
  height: 20px;
}

.payment-button:disabled {
  background-color: #cbd5e0;
  cursor: not-allowed;
  transform: none;
}

.accompanying-person-section {
  margin: 2rem 0;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.accompanying-person-section h4 {
  margin: 0 0 1rem 0;
  color: #333;
}

.accompanying-options {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.radio-group input[type="radio"] {
  margin: 0;
}

.radio-group label {
  font-size: 0.95rem;
  color: #444;
}

.accompanying-name {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;
}

.accompanying-name label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.accompanying-name input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
}

.amount-breakdown {
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
}

.amount-breakdown div {
  padding: 3px 0;
}
.accompanying-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
}

.accompanying-title {
  font-size: 1.1rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 600;
}

.accompanying-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.accompanying-item {
  animation: slideIn 0.3s ease-out;
}

.accompanying-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #e1e4e8;
  transition: border-color 0.2s;
}

.accompanying-input-group:hover {
  border-color: #7f2d5e;
}

.accompanying-number {
  min-width: 24px;
  height: 24px;
  background: #edf2f7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #4a5568;
  font-weight: 500;
}

.accompanying-input {
  flex: 1;
  border: none !important;
  padding: 8px !important;
  background: transparent !important;
}

.accompanying-input:focus {
  outline: none;
}

.accompanying-remove-btn {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  border: none;
  background: #fee2e2;
  color: #dc2626;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.accompanying-remove-btn:hover {
  background: #fecaca;
  transform: scale(1.05);
}

.accompanying-add-btn {
  background: white;
  border: 1px dashed #cbd5e0;
  padding: 12px;
  border-radius: 6px;
  color: #4a5568;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;
  margin-top: 8px;
}

.accompanying-add-btn:hover {
  border-color: #7f2d5e;
  background: #fdf4f9;
}

.accompanying-add-icon {
  font-size: 1.2rem;
  color: #7f2d5e;
}

.accompanying-price {
  color: #718096;
  font-size: 0.9rem;
}

.amount-breakdown {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #e2e8f0;
}

.breakdown-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: #4a5568;
  font-size: 0.95rem;
}

.breakdown-amount {
  font-weight: 600;
  color: #2d3748;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.registration-form input:focus,
.registration-form textarea:focus {
  border-color: #7f2d5e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(127, 45, 94, 0.1);
}

.accompanying-input-group:hover {
  border-color: #7f2d5e;
}

.accompanying-add-btn:hover {
  border-color: #7f2d5e;
  background: #fdf4f9;
}

.accompanying-add-icon {
  color: #7f2d5e;
}

section h2 {
  color: #7f2d5e;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 15px;
}

section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #7f2d5e, #a23d7e);
  border-radius: 2px;
}

.section-title {
  color: #7f2d5e;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 1rem;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #7f2d5e, #a23d7e);
  border-radius: 2px;
}

.section-subtitle {
  font-size: 1.5rem;
  color: #4a5568;
  text-align: center;
  margin-bottom: 1.5rem;
}

.success-container {
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.success-message {
  margin: 20px 0;
  line-height: 1.6;
  color: #4a5568;
}

.home-button {
  background-color: #7f2d5e;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.home-button:hover {
  background-color: #662048;
  transform: translateY(-2px);
}