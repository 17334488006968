.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #8e44ad;
  color: white;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.menu-toggle {
  display: none;
}

.drawer {
  display: flex;
  flex-direction: row;
  position: static;
  width: auto;
  height: auto;
  background-color: transparent;
  transition: none;
}

.drawer.open {
  left: 0;
  display: flex;
}

.drawer a {
  color: white;
  text-decoration: none;
  margin: 10px 0;
  font-size: 1.2rem;
}

.drawer a:hover {
  text-decoration: underline;
}

.navbar-logo {
  height: 40px;
  width: auto;
  margin: 0 20px;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    order: 1;
  }

  .navbar-logo {
    order: 2;
  }

  .drawer {
    order: 3;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #8e44ad;
    flex-direction: column;
    transition: left 0.3s ease;
    display: none;
  }

  .drawer.open {
    left: 0;
    display: block;
  }
} 